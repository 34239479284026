@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: block;
    font-weight: 300;
    src: local(''),
    url('./assets/fonts/inter-v12-latin-300.woff2') format('woff2'),
    url('./assets/fonts/inter-v12-latin-300.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: local(''),
    url('./assets/fonts/inter-v12-latin-regular.woff2') format('woff2'),
    url('./assets/fonts/inter-v12-latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: block;
    font-weight: 500;
    src: local(''),
    url('./assets/fonts/inter-v12-latin-500.woff2') format('woff2'),
    url('./assets/fonts/inter-v12-latin-500.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: block;
    font-weight: 600;
    src: local(''),
    url('./assets/fonts/inter-v12-latin-600.woff2') format('woff2'),
    url('./assets/fonts/inter-v12-latin-600.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: local(''),
    url('./assets/fonts/inter-v12-latin-700.woff2') format('woff2'),
    url('./assets/fonts/inter-v12-latin-700.woff') format('woff');
}